.aside {
  width: 31.2%;
  min-width: 200px;
}
.sorting {
  display: flex;
  align-items: center;
  column-gap: 16px;
}
.sorting__select-wrapper {
  width: 100%;
  position: relative;
}
.aside__list-wrapper {
  border-top: 1px solid rgba(255, 255, 255, 0.4);
}
.sorting__select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
  line-height: 24px;
  font-family: Rubik;
  color: rgba(0, 0, 0, 0.4);
}
.sorting__select-arrow {
  position: absolute;
  right: 16.7px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.4;
  pointer-events: none;
}
.sorting__button-wrapper {
  display: flex;
  width: calc(100% - 68.75%);
  column-gap: 16px;
  justify-content: flex-end;
}
.sorting__button {
  min-width: 24px;
  height: 24px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}
.sorting__button svg {
  pointer-events: none;
}
.sorting__button.disabled svg {
  fill: rgba(255, 255, 255, 0.4);
}
.sorting__button.active svg {
  fill: rgba(255, 255, 255);
}
.sorting__button.down {
  transform: rotate(180deg);
}
.sorting__button:hover {
  background-color: rgba(110, 62, 148, 0.5);
  border-radius: 4px;
}

@media screen and (max-width: 768px) {
  .aside {
    width: 100%;
  }
}
