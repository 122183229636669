@font-face {
    font-family: 'Popins Medium';
    src: url('./assets/fonts/Poppins-Medium.ttf');
  }
  @font-face {
    font-family: 'Popins Regular';
    src: url('./assets/fonts/Poppins-Regular.ttf');
  }
  @font-face {
    font-family: 'Poppins Light';
    src: url('./assets/fonts/Poppins-Light.ttf');
  }
  @font-face {
    font-family: 'CinzelDecorative Bold';
    src: url('./assets/fonts/CinzelDecorative-Bold.ttf');
  }
  
  @font-face {
    font-family: 'CinzelDecorative Black';
    src: url('./assets/fonts/CinzelDecorative-Black.ttf');
  }
  
  @font-face {
    font-family: 'CinzelDecorative Regular';
    src: url('./assets/fonts/CinzelDecorative-Regular.ttf');
  }
  
  :root{
    --text-color: #fff;
    --text-on-white: #000;
    --current-font-family: 'Popins Regular';
  }

  .header-mod-2 {
    pointer-events: none;
  }
  .mod-2 .footer{
    position: fixed;
    bottom: 0px;
    width: 100%;
    left: 0px;
    z-index: 6
  }
  .header-mod-2 * {
    pointer-events: all;
  }
  body.mod-2 {
    margin: 0;
    font-family: var(--current-font-family);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: linear-gradient(0deg, rgba(40, 40, 40, 0.2), rgba(40, 40, 40, 0.2)), #231F43;
    color: var(--text-color);
    max-width: 100%;
    /* overflow: hidden; */
    max-height: 100vh;
  }
  .mod-2 #root{
    background: linear-gradient(0deg, rgba(40, 40, 40, 0.2), rgba(40, 40, 40, 0.2)), #231F43 !important;
    /* display:flex; */
    /* flex-direction: column; */
    min-height:100vh;

  }
  .header-mod-2{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;margin: 0px !important;
    max-width: 100%;padding: 50px 60px 0px;
    box-sizing: border-box;z-index: 7;
  }
  header{
    /* width: 100%; */
    
    
    /* position: fixed; */
    
    /* flex: 0 0 auto; */
    /* top: 0px; */
  }
  /* main{
    flex: 1 0 auto;
  } */
 
  #root{
    /* background-image: url('./assets/img/bg.png');
    background-repeat: no-repeat;
    background-position:center center;
    background-size: auto 100%; */
  }
  footer{
    flex: 0 0 auto;
  }
  .mod-2 ul,p{
    margin-top: 0;
  }
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  
  img{
    max-width: 100%;
  }
  
  .mod-2 button,.btn{
    font-family:var(--current-font-family);
    color: var(--text-color);
    background-color:unset;
    border: none;
    padding: 5px 10px;
    white-space: nowrap;
    cursor: pointer;
    text-decoration: none;
  }
  
  .li-none-list-style{
    list-style: none;
  }
  .location .top{
    padding-bottom: 10px;
    margin: 0;
  }
  .d-flex{
    display:flex
  }
  .f-wrap{
    flex-wrap: wrap;
  }
  .justify-content-end{
    justify-content: flex-end;
  }
  .justify-content-between{
    justify-content: space-between;
  }
  .align-center{
    align-items: center;
  }
  .col-gap-1{
    column-gap: 5px;
  }
  .col-gap-2{
    column-gap: 10px;
  }
  .col-gap-3{
    column-gap: 15px;
  }
  .col-gap-4{
    column-gap: 20px;
  }
  .col-gap-5{
    column-gap: 25px;
  }
  .py-3{
    /* padding-top: 30px; */
    padding-bottom: 30px;
  }
  .mr-6{
    margin-right: 60px;
  }
  .mb-2{
    margin-bottom:20px
  }
  .my-4{
    margin-top: 40px;
  }
  .my-5{
    margin-top: 50px;
  }
  .my-6{
    margin-top: 60px;
  }
  .my-7{
    margin-top: 70px;
  }
  .my-8{
    margin-top: 80px;
  }
  .my-9{
    margin-top: 90px;
  }
  .mx-1{
    margin-left: 10px; 
    margin-right: 10px;
  }
  .mx-2{
    margin-left: 20px; 
    margin-right: 20px;
  }
  .mx-3{
    margin-left: 20px; 
    margin-right: 20px;
  }
  .mx-4{
    margin-left: 30px; 
    margin-right: 30px;
  }
  .mx-5{
    margin-left: 40px; 
    margin-right: 40px;
  }
  .mx-5{
    margin-left: 50px; 
    margin-right: 50px;
  }
  .mx-6{
    margin-left: 60px; 
    margin-right: 60px;
  }
  
  .x-200px{
    width: 200px;
    max-width: 100%;
  }
  .x-400px{
    width: 400px;
    max-width: 100%;
  }
  .header-nav-list,
  .footer-content-paragraph{
    margin:0px;
  }
  .footer-content-paragraph{
    color: var(--text-on-white)
  }
  
  .footer-content-bg{
    position: relative;
    z-index: 1;
  }
  .footer-content-bg::before{
    content:'';
    position: absolute;
    width: 210%;
    height: 280%;
    left:50%;
    top:50%;
    transform: translate(-50%,-50%);
    background-image: url('./assets/svg/footer-shape.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    z-index: -1;
  }
  .mod-2 .logo{
    width: 35%;
    max-width: 300px;
    min-width: 150px;
  
  }
  .footer-add-img{
    position:absolute;
    /* left:-500px; */
    bottom:0;
    width: 25%;
    max-width: 100%;
    min-height: 70vh;
    max-height: calc(100% - 135px);
    pointer-events:none;
    /* overflow: hidden */
    z-index: 1;
    transition: left 6.5s ease-out;
  }
  .footer-add-img::before{
    content:"";
    position:absolute;
    left: 0%;
    bottom: 10%;
    /* transform: translateY(-50%); */
    z-index: -1;
    max-width:500px;
    width: 120%;
    height: 60%;
    /* background-color: red; */
    background-image: url("./assets/svg/bg-shape.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left center;
  }
  .footer-add-img img{
    position:absolute;
    width: auto;
    height: 100%;
    object-fit: contain;
  }
  .container-min{
    width: 100%;
    max-width: 835px;
    margin: 0 auto;
    
  }
  .text-center{
    text-align: center;
  }
  .modal{
    position: absolute;
    z-index: 5;
    padding: 70px 110px;
    box-sizing: border-box;
    /* max-height: 60vh; */
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    /* overflow-y: auto; */
  }
  .modal::before{
    content:"";
    position: absolute;
    width: 100%;
    height: 100%;
    left:0;
    top: 0;
    z-index: -1;
    background: #000000;
  opacity: 0.7;
  filter: blur(30px);
    /* box-shadow:inset 0px 0px 20px 40px rgba(0, 0, 0, 0.5) , 0px 0px 20px 40px rgba(0, 0, 0, 0.5)  ; */
  }
  
  .justify-content-center{
    justify-content: center;
  }
  .location{
    position: fixed;;
    z-index: 12;
    pointer-events: none;
    bottom: 60px;
    left: 50%;
    transform: translateX(-50%);
    
  }
  
  
  .select-adventure{
    font-family: 'CinzelDecorative Bold';
    /* transition: opacity 0.; */
    transition: opacity 0.5s ease;
  }
  .select-adventure, .location-name{
    /* opacity: 0; */
    /* transition: opacity 0.5s ease; */
    /* transition-delay: 0.2s ; */
    /* animation: showAdventure 1s forwards; */
  }
  .select-adventure span.deactive{
    /* opacity: 0; */
    animation: showAdventure 1s forwards;
  }
  .select-adventure span.active{
    /* opacity: 0; */
    animation: hideAdventure 1s forwards;
  }

  @keyframes showAdventure {
    from{
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes hideAdventure {
    from{
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }


  .location .middle{
    /* font-family: 'Popins Regular'; */
  }
  .rotate-180deg{
    transform: rotate(180deg);
  }
  
  .circle{
    background-color: white;
    display: inline-block;
  }
  
  .wpx-2{
    width:2px;
    height:2px
  }
  .px-2{
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }
  .wpx-4{
    width:4px;
    height:4px
  }
  .wpx-6{
    width:6px;
    height:6px
  }
  .rounded-50{
    border-radius: 50%;
  }
  .mt-1{
    margin-top: 10px;
  }
  .mt-2{
    margin-top: 20px;
  }
  .mb-0{
    margin-bottom: 0px;
  }
  .hidden{
    display: none !important;
  }
  .location-name{
    font-family: 'CinzelDecorative Bold';
    transition: opacity 0.2s ease-out;
    /* font-size: 24px; */
    /* line-height:40px; */
  }
  .location-or{
    font-size: 16px;
  line-height: 24px;
  }
  .modal-wrapper{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    display:flex;
    justify-content:center;
    align-items:center;
    background-color: rgba(40, 40, 40, 0.5);
    transition:  background-color 0.2s ease;
  }
  .map-item{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50px;
    height: auto;
    transform: translate(-50%,-50%);
    border: 2px solid;
    border-color: transparent;
    border-radius: 50%;
    padding:5px;
    cursor: pointer;
  }
  .modal-var-2 {
    transition: opacity 0.5s ease;
  }
  .modal-var-2 img{
    opacity: 0;
    animation: modalOpen ease-out;
    animation-duration: 0.5s;
    animation-delay: 0s;
    animation-fill-mode: forwards;

  }  

  @keyframes modalOpen {
    from {
      opacity: 0;
      transform: translateX(-100vh);
    }
    to{
      opacity: 1;
      transform: translateX(0vh);
    }
  }

  .map-item-img{
    position: relative;
    width: 100%;
    padding-top: 100%;
  }
  .map-item-img img{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%) scale(1);
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: transform 0.2s ease-out;
  }
  .map-item-img img:hover{
    transform: translate(-50%,-50%) scale(1.3);
  }
  .vh-100{
    height: 100vh !important;
    min-height: 100vh !important;
  }
  .with-out-before:before{
    display:none
  }
  .modal-img-wrap{
    position: relative;
    height: 100%;
    width: 100%;
  }
  /* .modal-img-wrap img{
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
    left: 0;
    top: 0;
  } */
  .py-0{
    padding-top:0px;
    padding-bottom:0px;
  }
  .px-3{
    padding-left: 30px;
    padding-right: 30px;
  }
  
  /* .active .location-or{
    display:none
  }
  .active .select-adventure, .active .start{
    display:none
  }
  .location-name,.bubbles,.located {
    display:none
  }
  .active .location-name, .active .located{
    display:block
  }
  .active .bubbles{
    display:flex
  } */
  .start{
    pointer-events:all;
  }
  .bubbles{
    height: 22px
  }
  .position-absolute{
    position: absolute;
  }
  .preloader{
    position: absolute;
    height: 100%;
    width: 100%;
    display:flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    transition: opacity 1.3s ease-out;
    z-index: 100;
    background: linear-gradient(0deg, rgba(40, 40, 40, 0.2), rgba(40, 40, 40, 0.2)), #231F43;
  }
  .preloader-inner{
    display:flex;
    flex-direction: column;
  
  }
  .preloader-text p{
    font-size: 36px;
    margin-right: 10px;
    margin-bottom: 0px;
  }
  .preloader-svg svg{
    position: absolute;
    height: 40px;
    width: auto;
    transform:translateY(48%)
  }
  .loaded .preloader{
    opacity: 0;
    animation-name: displaynone;
    animation-fill-mode: forwards;
    animation-delay: 1.3s;
  }
  @keyframes displaynone {
    to{
  
      pointer-events: none;
      z-index: -1;
    }
  }
  
  .loaded .footer-add-img{
    animation : leftOut 1.3s ease-out;
    animation-fill-mode: forwards;
  }
  .footer-content-bg{
    /* transform: translate(0px,500px); */
  }
  .loaded .footer-content-bg{
    animation : rightOut 1.3s ease-out;
    animation-fill-mode: forwards;
  }
  
  
  @keyframes leftOut {
    to {
      left: 0px
    }
  }
  @keyframes rightOut {
    to {
      transform: translate(0px,0px)
    }
  }
  
  .px-0{
    padding-left: 0px;
    padding-right: 0px;
  }
  
  .mx-5{
    margin-left: 50px;
    margin-right: 50px;
  }
  .modal-img{
    max-height: 100vh;
  }
  .Map{
    transform-origin: 50% 50%;
    position: absolute;
    left: 50%;
    top:50%;
    transform: translate(-50%,-50%);
    width: 100%;
    height: 100vh !important;
    max-height: 100vh !important;
    overflow: hidden;
    z-index: 1;
  }
  .test-item{
    /* transform-origin: 50% 50%; */
  }
  .test-item{
    cursor: pointer;
    /* transform: scale(1); */
    /* transition:  transform 500ms; */
    /* transform: translateX(-1%); */
  }
  .test-item:hover{
    /* transform: translate(-7px,-7px); */
    /* z-index: 5; */
    /* transform: scale(2) !important; */
  }
  .Map svg{
    width: 100%;
    height: auto;
  }
  .map-item-wrap {
    width: 100%;
    height: 100%;
    position:absolute;
    z-index: 5;
    pointer-events: none;
  }
  .map-item{
    pointer-events: all;
  }
  
  .map-2{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow:hidden
  
  }
  .map-2 svg{
    min-width: 100%;
    min-height: 100%;
  }
  
  figure {
    background: #f3f3f3;
    border-radius: 50%;
    height: 66px;
    width: 66px;
    box-shadow: 0px 5px 5px 0px blue;
    overflow: hidden;
  }
  
  figure img {
    height: auto;
    width: 100%;
  }
  .--mobile{
    display: none;
  }
  
  .pointer-events-none{
    pointer-events: none;
  }
  .cursor-pointer{
    cursor: pointer;
  }
  
  .map-2 .map-marker{
    -webkit-transform: translate3d(0,0,0) !important;
  }
  .test-verst-marker{
    display: block;
    position: absolute;
    width: 70px;
    height: 70px;
    left: -10000px;
    top: -10000px ;
    transform: scale(1);
    transition: transform 0.5s ease-out;
    z-index: 1;
  }

  /* .test-verst-marker.hovered{
    z-index: 9999;
    transform: scale(1) rotate(-30px);
  } */
  .test-verst-marker img{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%) scale(1) rotate(0deg);
    object-fit:contain;
    transition: transform 0.2s ease-out;
  }
  .test-verst-marker.hovered img{
    transform: translate(-50%,-50%) scale(1.2) rotate(-10deg);
  }

  @media screen and (max-height:600px) {
  
    .header-mod-2 {
      margin: 20px 10px 0;
    }
    .header-mod-2 .logo{
      width: unset;
      height: 80px;
    }
    .header-mod-2 .logo img{
      max-height: 100%;
    }
  }
  @media screen and (max-width:1100px) {
    .header-mod-2 {
      padding: 30px 20px 0;
    }
    .footer-content-bg {
      margin-right: 10px;
      margin-bottom: 10px;
    }
    .location{
      bottom: 80px;
    }
    .header-mod-2 .logo{
      width: unset;
      height: 100px;
    }
    .header-mod-2 .logo img{
      max-height: 100%;
    }
  }
  .modal-inner{
    max-height: 100%;
    overflow-y: auto;
    max-height: calc(60vh - 40px);
    overflow-y: auto;
  }
  @media screen and (max-width:880px) {
    /* .footer-add-img{
      min-height: 70vh;
    } */
    .modal {
      /* overflow-y: auto */
      padding: 20px;
    }
    .header-nav {
      padding: 0px;
    }
    .location{
      /* display: none; */
      bottom: 80px;
    }
    .footer-content-bg {
      margin-right: 10px;
      margin-bottom: 10px;
    }
    .header-nav-list{
      /* display: none; */
    }
    svg {
      display: inline-block;
      vertical-align: middle;
    }
    
    .header-nav-list{
      display: flex;
      flex-direction: column;
      position: fixed;
      justify-content: flex-start;
      align-items: center;
      left: 0;
      height: calc(100vh);
      max-height: 100vh;
      overflow-y: auto;
      top: 0;
      width: 100%;
      background: linear-gradient(0deg, rgba(40, 40, 40, 0.2), rgba(40, 40, 40, 0.2)), #231f43c4;
      padding-left: 0px;
      padding-top: 90px;
      padding-bottom: 20px;
      row-gap: 10px;
      left: 110%;
      transition: left 0.3s ease-out;
      box-sizing: border-box;
    }
    .header-nav-list.active{
      left: 0%;
    }
    .header-mod-2 {
      margin: 20px 10px 0;
    }
    .header-mod-2 .logo{
      width: unset;
      height: 80px;
      /* position: relative; */
      /* z-index: 100; */
    }
    .header-mod-2 .logo img{
      max-height: 100%;
    }
    .--mobile{
      display: block;
      position: relative;
      z-index: 100;
    }
  }
  .m-0{
    margin: 0;
  }
  .pr-1{
    /* padding-right: 10px; */
  }
  .header-nav-list .btn:hover{
    text-decoration: underline;
  }