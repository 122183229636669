.filter-wrapper {
  margin: 32px 0;
  cursor: pointer;
}
.filter-wrapper svg {
  fill: white;
  transition: fill 0.3s ease-out;
}
.filter-wrapper.closed svg {
  fill: rgba(255, 255, 255, 0.4);
}
.filter {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  font-family: "Rubik";
  color: #fff;
}

@media screen and (min-width: 769px) {
  .filter-wrapper {
    display: none;
  }
  .aside__list {
    /* margin-top: 24px; */
    max-height: unset;
  }
}
@media screen and (max-width: 768px) {
  .aside__list {
    max-height: 3000px;
    transition: max-height 0.3s ease-out;
  }
  .aside__list.disabled {
    max-height: 0px;
    overflow: hidden;
    margin-top: 0px;
  }
}
