body {
  position: relative;
  z-index: -999;
}

input {
  font-size: 20px;
}
input::-webkit-input-placeholder {
  font-size: 20px;
  line-height: 3;
}

input {
  padding: 20px;
  font-size: 22px;
  border-radius: 15px;
  border: 1px solid #777e90;
}

button {
  border-radius: 10px;
  padding: 20px 40px;
  font-size: 22px;
  color: white;
  font-weight: 500;
  background-color: #542c73;
}

.customButton {
  border-radius: 10px;
  padding: 20px 40px;
  font-size: 22px;
  color: white;
  font-weight: 500;
  background-color: #542c73;
}

button:disabled {
  background: #b1b4ba;
}

.roadmap__link {
  color: white;
}

.roadmap__landinglink {
  color: #777e90;
  margin-right: 10px;
  font-family: 'Rubik';
  cursor: pointer;
}

.roadmap__link {
  margin-right: 10px;
  font-family: 'Rubik';
  cursor: pointer;
}

.roadmap__link:last-child {
  margin-right: 0px;
}
.bunny-map {
  padding: 520px 0 0 0;
  background: url('../img/bunny-map.png') center no-repeat;
  background-size: auto;
  margin-bottom: 180px;
}

.swiper-button-prev {
  margin-top: 40px;
  color: lightgrey;
  cursor: default;
}
.swiper-button-next {
  margin-top: 40px;
  color: lightgrey;
  cursor: default;
}

@media screen and (min-width: 319px) {
  ._mob {
    display: block;
  }

  .buybunny input {
    /* max-width: 180px; */
    margin-right: 10px;
  }

  .buybunny__block {
    padding-right: 40px;
  }
  .buybunny__rarity {
    padding: 0;
  }

  .container {
    margin: 0 auto;
    width: 86.6%;
  }

  .buybunny__timer-time {
    padding-top: 20px;
    font-size: 32px;
    font-weight: 500;
  }
  .buybunny__timer {
    padding: 20px 20px;
    border-radius: 10px;
    border: 1px solid #777e90;
  }

  .wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: 100%;
    background: #fff;
    overflow: hidden;
    position: relative;
    background: url(../img/page-bg.png) center no-repeat;
    background-size: cover;
    background-position: 0 0;
    padding: 0 0 58px 0;
    z-index: -99;
  }
  .wrapper-roadmap {
    background: url(../img/roadmap-bg-mob.png) center no-repeat;
    background-size: cover;
  }

  .main {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .header {
    padding: 193px 0 165px 0;
    background: url("../img/rabbits.png") 110% 11% no-repeat;
    background-size: 66.6666666667%;
  }
  .header-roadmap {
    background: none;
    padding: 88px 0 97px 0;
  }
  .header-roadmap .logo::before {
    font-size: calc(32px + 0 * (100vw / 1960));
    color: #876b9d;
  }
  .header__title {
    font-family: 'Sen', sans-serif;
    line-height: 100%;
    color: #ffffff;
    margin-bottom: 36px;
    font-size: calc(56px + 24 * (100vw / 1960));
  }
  .header__text {
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    font-size: calc(18px + 6 * (100vw / 1960));
    line-height: 133%;
    color: #ffffff;
    margin-bottom: 36px;
  }
  .header__text_bold {
    font-family: 'Rubik', sans-serif;
    font-weight: 700;
  }
  .header__line {
    display: block;
    width: 60%;
    border: 1px solid #fff;
    margin: 0 auto;
    margin-bottom: 24px;
  }
  .legend__line {
    display: block;
    width: 25%;
    border: 1px solid #fff;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 60px;
  }
  .header__bold {
    text-align: center;
    font-weight: bold;
    font-size: calc(18px + 14 * (100vw / 1960));
  }
  .header__moonrabbit {
    display: block;
    margin: 0 auto;
    width: calc(66px + 55 * (100vw / 1960));
  }

  .navigation {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;
    padding: 4.7222222222% 0 0 0;
    width: 100%;
  }
  .navigation__content {
    display: flex;
    justify-content: space-between;
  }
  .navigation__column {
    display: flex;
    flex-direction: row;
    align-items: center;
    
  }

  .menu__burger {
    width: 24px;
    height: 24px;
    background: url("../img/menu.svg");
  }
  .menu__burger:hover {
    cursor: pointer;
  }

  /* .logo::before {
    font-size: calc(32px + 0 * (100vw / 1960));
    color: #777e90;
  } */

  .logo {
    font-size: 16px;
    line-height: 32px;
    color: #fff;
    padding-left: 48px;
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    position: relative;
    cursor: pointer;
  }
  .logo::before {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    font-size: calc(32px + 0 * (100vw / 1960));
    color: #777e90;
  }

  .social {
    display: block;
    margin: 0 16px;
  }
  .social::before {
    font-size: calc(24px + 0 * (100vw / 1960));
    color: #996bb3;
  }
  .social__column {
    margin: 0 -16px;
  }

  .heroes {
    background: #fff;
    position: relative;
    min-width: 0;
  }
  .heroes_image {
    position: relative;
    padding: 0 0 165px 0;
  }
  .heroes__wrapper {
    overflow: visible;
  }
  .heroes__slide {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 28px 40px 20px;
  }
  .heroes__title {
    color: rgba(0, 0, 0, 0.9);
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
    font-size: calc(30px + 0 * (100vw / 1960));
    line-height: 100%;
    margin-bottom: 24px;
  }
  .heroes__text {
    color: #777e90;
    font-size: calc(16px + 2 * (100vw / 1960));
    line-height: 133%;
    text-align: center;
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
  }
  .heroes__img {
    max-width: calc(165px + 111 * (100vw / 1960));
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -38.5%);
    z-index: 20;
  }

  .trait {
    padding: 56px 0 80px 0;
  }
  .trait__title {
    font-size: calc(32px + 0 * (100vw / 1960));
    line-height: 100%;
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
    color: #ffffff;
    margin: 0 0 24px 0;
    text-align: center;
  }
  .trait__text {
    text-align: center;
    font-size: calc(16px + 8 * (100vw / 1960));
    line-height: 133%;
    color: #ffffff;
    max-width: 100%;
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
  }
  .trait__img {
    width: 100%;
    margin: 0 0;
  }
  .trait__img._mob {
    margin: 40px 0;
  }
  .map__img {
    width: 100%;
    margin: -10px 30px 120px 30px;
  }

  .story {
    padding-bottom: 80px;
  }
  .story__icon {
    display: block;
    color: #ffffff;
    font-size: calc(32px + 0 * (100vw / 1960));
    margin: 0 auto 24px auto;
    text-align: center;
  }
  .story__text {
    font-style: normal;
    text-align: center;
    font-size: calc(16px + 2 * (100vw / 1960));
    line-height: 133%;
    color: #ffffff;
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    margin: 0 auto;
  }
  .story__line {
    display: block;
    border: 1px solid #fff;
    width: 60%;
    margin: 32px auto 7% auto;
  }
  .story__link {
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    color: #ffffff;
    text-decoration: underline;
  }

  .text_italy {
    font-size: calc(18px + 6 * (100vw / 1960));
    font-style: italic;
  }

  .bunniverse {
    margin-bottom: 140px;
  }
  .bunniverse__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-direction: column-reverse;
  }
  .bunniverse__title {
    text-align: center;
    font-size: calc(56px + 16 * (100vw / 1960));
    line-height: 100%;
    font-family: 'Sen', sans-serif;
    color: #fff;
    margin: 0;
    margin-bottom: 24px;
  }
  .bunniverse__text {
    color: #fff;
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    font-size: calc(16px + 8 * (100vw / 1960));
    line-height: 133%;
  }
  .bunniverse__block:first-child {
    margin-bottom: 15px;
  }

  .rarity {
    padding: 80px 0 0 0;
    background-color: #fff;
    margin-bottom: 0;
  }
  .buybunny {
    padding: 80px 0;
    background-color: #fff;
    margin-bottom: 140px;
  }
  .buybunny__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .buybunny__title {
    font-size: calc(32px + 0 * (100vw / 1960));
    line-height: 100%;
    font-family: 'Sen', sans-serif;
    color: #000;
    margin: 0;
    margin-bottom: 24px;
  }
  .buybunny__text {
    color: #000;
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    font-size: calc(16px + 8 * (100vw / 1960));
    line-height: 133%;
  }
  .buybunny__block:first-child {
    margin-bottom: 15px;
  }

  .video {
    display: block;
    position: relative;
    width: 100%;
  }

  .footer {
    width: 100%;
    position: absolute;
    bottom: 58px;
    left: 0;
  }
  .footer__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .footer__text {
    color: #fbfbfb;
    margin-top: 16px;
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    font-size: calc(16px + 2 * (100vw / 1960));
    line-height: 133%;
  }
  .footer__logo {
    font-size: calc(32px + 0 * (100vw / 1960));
    color: #f3cefe;
  }

  .roadmap__title {
    font-size: calc(56px + 24 * (100vw / 1960));
    line-height: 100%;
    color: rgba(255, 255, 255, 0.9);
    text-align: center;
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
    margin-bottom: 24px;
  }
  .roadmap__text {
    font-size: calc(16px + 8 * (100vw / 1960));
    line-height: 133%;
    text-align: center;
    color: #ffffff;
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
  }
  .roadmap__text span {
    display: block;
  }
  .roadmap__text span:not(:last-child) {
    margin-bottom: 26px;
  }

  .phase {
    margin: -140px 0;
  }
  .phase .container {
    width: 100%;
  }
  .phase__card {
    background: #fff;
    margin: 140px 0;
  }
  .phase__card .container {
    margin: 0 auto;
    width: 86.6%;
  }
  .phase__content {
    padding: 110px 0 40px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  .phase__imagebox {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -62%);
    height: 204px;
    display: flex;
    align-items: flex-end;
  }
  .phase__title {
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
    font-size: calc(32px + 0 * (100vw / 1960));
    line-height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    color: rgba(0, 0, 0, 0.9);
    margin-bottom: 24px;
  }
  .phase__text {
    font-size: calc(16px + 2 * (100vw / 1960));
    line-height: 133%;
    text-align: center;
    color: #777e90;
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    margin-bottom: 32px;
  }
  .phase__list {
    width: 100%;
    font-size: calc(16px + 2 * (100vw / 1960));
    color: #777e90;
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
  }
  .phase__list-item:not(:last-child) {
    margin-bottom: 16px;
  }

  .communiti {
    padding: 80px 0 176px 0;
  }
  .communiti__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .communiti__title {
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
    font-size: calc(32px + 0 * (100vw / 1960));
    line-height: 100%;
    color: rgba(255, 255, 255, 0.9);
    text-align: center;
  }
  .communiti__text {
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    font-size: calc(16px + 2 * (100vw / 1960));
    line-height: 133%;
    text-align: center;
    color: #ffffff;
    margin: 34px 0 38px 0;
  }
  .communiti__button {
    display: inline-block;
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
    font-size: calc(18px + 0 * (100vw / 1960));
    line-height: 133%;
    text-align: center;
    color: #542c73;
    padding: 16px 40px;
    background: #fff;
    border-radius: 6px;
  }

  ._pc {
    display: none;
  }
}
@media screen and (min-width: 720px) {
  .header {
    padding-top: 250px;
  }
  .header__title {
    margin-bottom: 15%;
  }
  .bunniverse__title {
    text-align: center;
  }

  .buybunny__title {
    padding-top: 20px;
    font-size: calc(24px + 16 * (100vw / 1960));
  }
}
@media screen and (min-width: 900px) {
  .header {
    padding-top: 250px;
  }
  .header__title {
    margin-bottom: 25%;
  }
  .bunniverse__title {
    text-align: left;
  }
  .legend__line {
    margin-left: 0;
  }
  .bunniverse__content {
    flex-direction: column-reverse;
  }
}
@media screen and (min-width: 1200px) {
  ._pc {
    display: block;
  }

  .bunniverse__title {
    text-align: left;
  }

  .legend__line {
    margin-left: 0;
  }

  .wrapper {
    background: url(../img/pc_bg.png) center no-repeat;
    background-size: cover;
    background-position: 0 0;
  }
  .wrapper-roadmap {
    background: url(../img/roadmap-bg-pc.png) center no-repeat;
    background-size: cover;
  }

  .container {
    height: 100%;
    width: 86.6%;
  }

  .header {
    width: 100%;
    padding: 352px 0 318px 0;
    background: url('../img/rabbits.png') 25% 16% no-repeat;
    background-size: 36%;
  }
  .header-roadmap {
    background: none;
    padding: 184px 0 200px 0;
  }
  .header-roadmap .logo::before {
    font-size: calc(32px + 0 * (100vw / 1960));
    color: #777e90;
  }
  .header__content {
    display: flex;
    flex-direction: column;
  }
  .header__title {
    align-self: flex-end;
    max-width: 45%;
    margin-bottom: 40px;
  }
  .header__text {
    align-self: flex-end;
    max-width: 45%;
  }
  .header__line {
    width: 25%;
    margin-top: 16%;
  }

  .header__bold {
    align-self: center;
  }
  .navigation {
    position: fixed;
    height: 88px;
    background: #fff;
    padding: 0;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.02);
  }
  .navigation__content {
    height: 100%;
    align-items: center;
  }
  .navigation__column {
    margin: 0 -16px;
  }
  .navigation__link {
    margin: 0 16px;
    color: #777e90;
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    font-size: calc(18px + 0 * (100vw / 1960));
  }
  .navigation__link:hover,
  .navigation__social:hover {
    cursor: pointer;
    opacity: 0.75;
  }

  .logo {
    margin: 0 16px;
  }

  .social::before {
    color: #777e90;
  }
  .heroes__img {
    min-width: 220px;
    transform: translate(-50%, 0);
  }
  .heroes__slide {
    padding: 0;
    height: 100%;
  }
  .heroes__title {
    text-align: center;
    margin-bottom: 40px;
  }
  .heroes__text {
    display: flex;
    flex-direction: column;
  }
  .heroes__text span {
    display: block;
    flex: 1 1 100%;
    margin-bottom: 20px;
  }

  .heroes_image {
    padding: 0 0 373px 0;
  }

  .slider-container {
    width: 58.3%;
    margin: 0 auto;
    overflow: hidden;
    transform: translateY(-38.5%);
  }

  .trait {
    padding: 144px 0 151px 0;
  }
  .trait__content {
    display: flex;
    margin: 0 -36px;
  }
  .trait__block {
    flex: 0 1 50%;
    padding: 0 36px;
  }
  .trait__title {
    text-align: left;
    padding-top: 40px;
  }
  .trait__text {
    text-align: left;
  }
  .trait__text_1 {
    margin-bottom: 55px;
  }
  .story {
    padding-bottom: 0;
  }
  .story__text {
    max-width: 74%;
  }
  .story__text_1 {
    margin-bottom: 63.5px;
  }
  .story__text_2 {
    margin-bottom: 216px;
  }
  .story__line {
    margin-bottom: 1.15%;
    width: 21.5%;
  }
  .story__link {
    display: inline-block;
  }

  .bunniverse {
    margin-bottom: 40px;
  }
  .bunniverse__title {
    margin-bottom: 48px;
  }
  .bunniverse__content {
    flex-direction: row;
  }
  .bunniverse__block {
    flex: 0 0 48%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .buybunny {
    padding: 80px 0;
    margin-bottom: 180px;
  }

  .buybunny__price {
    padding-bottom: 20px;
    /* font-size: 20px; */
    font-weight: bold;
  }
  .buybunny__timer-time {
    padding-top: 20px;
    font-size: 32px;
    font-weight: 500;
  }
  .buybunny__timer {
    max-width: 357px;
    padding: 20px 20px;
    border-radius: 10px;
    border: 1px solid #777e90;
  }
  .buybunny__title {
    font-size: calc(43px + 8 * (100vw / 1960));
    margin-bottom: 48px;
  }
  .buybunny__content {
    flex-direction: row;
  }
  .buybunny__block {
    z-index: 1;
    flex: 0 0 48%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .video {
    z-index: -1;
    max-width: 100%;
    align-self: flex-end;
  }

  /* button.buy {
    border-radius: 15px;
    padding: 20px;
    font-size: 20px;
    color: white;
    background-color: #996bb3;
  } */

  .footer {
    bottom: 76px;
  }
  .footer__logo {
    color: #fff;
  }
  .roadmap__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 120px;
  }
  .roadmap__title {
    font-size: 80px;
    line-height: 100%;
    margin-bottom: 40px;
  }
  .roadmap__text {
    font-size: 24px;
    line-height: 133%;
    max-width: 56.551%;
    text-align: center;
  }

  .phase {
    background: #fff;
    margin: 0;
  }
  .phase .container {
    height: 100%;
    width: 86.6%;
  }
  .phase__content {
    padding: 140px 0 111px 0;
  }
  .phase__bigcontent {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 -40.5px;
  }
  .phase__card {
    margin: 0 40.5px;
  }
  .phase__card .container {
    width: 100%;
    margin: 0;
  }
  .phase__title {
    margin-bottom: 40px;
    line-height: 100%;
  }
  .phase__text {
    margin-bottom: 40px;
    font-size: 18px;
    line-height: 133%;
  }

  .communiti {
    padding: 144px 0 279px 0;
  }
  .communiti__title {
    margin-bottom: 48px;
    font-size: 32px;
    line-height: 100%;
  }
  .communiti__text {
    margin-bottom: 56px;
    font-size: 24px;
    line-height: 133%;
    max-width: 56.51%;
  }

  ._mob {
    display: none;
  }

  .swiper-button-prev {
    display: none;
  }
  .swiper-button-next {
    display: none;
  }
}
@media screen and (min-width: 1540px) {
  .container {
    width: 58.3%;
  }

  .header {
    background-size: 27%;
  }
}
@media screen and (min-width: 2500px) {
  .header__line {
    margin-top: 13%;
  }

  .story__line {
    width: 20%;
  }
}

/* new*/
