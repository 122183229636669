.aside__list-item {
  display: inline-block;
  width: 100%;
}
.aside__list-item-inner {
  display: flex;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
  font-size: 20px;
  align-items: center;
  color: #fff;
  font-family: Rubik;
  margin: 16px 0;
}
.aside__list-item-drop-item {
  font-size: 16px;
  color: #fff;
  font-family: Rubik;
  line-height: 24px;
  cursor: pointer;
  opacity: 0.4;
}
.aside__list-item-drop-item:hover {
  opacity: 0.8;
}
.aside__list-item-title {
  max-width: 95%;
  word-break: break-all;
  text-transform: capitalize;
}
.aside__list-item-svg {
  height: 24px;
}
.aside__list-item-inner svg {
  margin: 5px;
}
.aside__list-item-inner ~ .aside__list-item-drop-list {
  height: max-content;
  max-height: 0;
  overflow: hidden;
  transition: 0.3s max-height ease-out;
}

.aside__list-item-inner.active ~ .aside__list-item-drop-list {
  max-height: 1500px;
}
@media screen and (max-width: 768px) {
  .aside__list-item-inner {
    margin: 24px 0;
  }
}
