.--default {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
}
.--clickable {
  cursor: pointer;
}
.--white {
  color: #fff;
}
.subscribe-page {
  min-height: calc(100vh - 58px);
  padding-top: 250px;
  padding-bottom: 168px;
  align-items: center;
  box-sizing: border-box;
}
.subscribe {
  width: 86%;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.subscribe__top {
  text-align: center;
}
.subscribe__title {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 80px;
  line-height: 86px;
}
.subscribe__paragraph {
  margin-top: 52px;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
}
.submit__form-wrapper {
  margin-top: 100px;
}
.submit__form-inner {
  display: flex;
  flex-wrap: wrap;
  column-gap: 16px;
  row-gap: 34px;
  justify-content: center;
}
.subscribe__submit-btn {
  border: 1px solid #fff;
  background-color: transparent;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  color: #fff;
  padding: 8px 80px;
  box-sizing: border-box;
  border-radius: 4px;
  transition: color 0.2s ease-out, background-color 0.2s ease-out;
}
.subscribe__submit-btn:hover {
  color: #542c73;
  background-color: #fff;
}
.subscribe__submit-input {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 100%;
  padding: 12px 20px;
  width: 310px;
  max-width: 100%;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.6);
  border: unset;
}
.subscribe__submit-input::placeholder {
  color: rgba(0, 0, 0, 0.3);
  font-size: 14px;
  line-height: 100%;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 100%;
}

@media screen and (max-width: 720px) {
  .subscribe-page {
    padding-top: 88px;
  }
}
@media screen and (max-width: 1200px) {
  .subscribe__title {
    font-size: calc(56px + 24 * (100vw / 1960));
    line-height: 100%;
  }
  .subscribe__paragraph {
    margin-top: 26px;
    font-size: calc(16px + 8 * (100vw / 1960));
    line-height: 133%;
  }
  .submit__form-wrapper {
    margin-top: 36px;
  }
}
