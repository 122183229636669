.main_gallery {
  display: flex;
  /* width: 58.33%; */
  column-gap: 32px;
  margin: 0 auto;
  padding-bottom: 168px;
}

@media screen and (max-width: 768px) {
  .main_gallery {
    flex-direction: column;
    row-gap: 40px;
  }
}
