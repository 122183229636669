.item {
  width: calc(33.333% - 22px);
  cursor: pointer;
}
.item__img-wrapper {
  position: relative;
  padding-top: 100%;
  border-radius: 8px;
  overflow: hidden;
}
.item__img__span {
  position: absolute;
  right: 19px;
  bottom: 19px;
  width: 18px;
  height: 18px;
  z-index: 2;
}
.item__img__span svg {
  width: 100%;
  height: 100%;
}
.item__img-wrapper .item__img__span svg {
  transition: transform 0.2s ease-out;
  cursor: pointer;
}
.item__img-wrapper:hover .item__img__span svg {
  transform: scale(1.1);
}
.item__img-wrapper img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  box-sizing: border-box;
}
.discription {
  display: flex;
  justify-content: space-between;
  margin: 16px;
  margin-bottom: 0;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.discription__title,
.discription__chance {
  font-family: "Rubik", sans-serif;
  color: #fff;
}
.discription__title {
  max-width: 100%;
  word-wrap: break-word;
}
@media screen and (max-width: 1130px) {
  .item {
    width: calc(50% - 16px);
  }
}
@media screen and (max-width: 380px) {
  .item {
    width: 100%;
  }
}
